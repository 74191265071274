<template>
  <f7-page class="">
    <template #fixed>
      <CommonNavigationComponent :title="$t.getTranslation('LBL_QR_SCAN')" type="back" :search="false" />
    </template>
    <div class="container survey-content">
      <img class="survey-list-top" :src="require('@/assets/images/qrcode-bg.png')" style="-webkit-filter: drop-shadow(20px 20px 20px #111); filter: drop-shadow(20px 20px 20px #111);" loading="lazy" />
      <QRScanComponent :Qrlink="link" :details="qrDetails" />
      <img class="survey-list-bot" :src="require('@/assets/images/qrcode-bg.png')" style="-webkit-filter: drop-shadow(20px 20px 20px #111); filter: drop-shadow(20px 20px 20px #111);" loading="lazy" />
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, onMounted, defineAsyncComponent } from 'vue'
import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { configs } from '@/utils/configs'
import { useStore } from '@/store'
// import CommonNavigationComponent from '@/components/navigations/CommonNavigationComponent.vue'
// import QRScanComponent from '@/components/QRScanComponent.vue'

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/CommonNavigationComponent.vue'))
const QRScanComponent = defineAsyncComponent(() => import(/* webpackChunkName: "qr-scan" */ /* webpackMode: "lazy" */ '@/components/QRScanComponent.vue'))

export default defineComponent({
  name: 'QRScanPage',
  components: {
    CommonNavigationComponent,
    QRScanComponent
  },
  props: {
    f7router: Object,
    f7route: Object
  },
  setup(props) {
    let key = ref(null)
    let type = ref(null)
    let link = ref(null)
    let qrDetails = ref(null)
    const $t = inject('$translation')

    const store = useStore()

    const getQrData = async () => {
      let baseURL = configs.originDomain
      if (configs.environment == 'production') {
        baseURL = `https://${baseURL}`
      } else {
        baseURL = `http://${baseURL}`
      }
      if (type.value == 'SURVEY') {
        //get the data of the survey of key is passed..
        let { SurveyName, SurveyDescription } = await get('/survey/view', {
          SurveyKey: key.value,
          LanguageCode: $t.getLanguage()
        })
        let obj = {
          name: SurveyName,
          description: SurveyDescription
        }

        qrDetails.value = obj
        link.value = baseURL + '/survey/'
      }

      //add other types in here if needed..
    }

    onMounted(() => {
      if (props?.f7route?.query?.key) {
        key.value = props?.f7route?.query?.key
      }
      if (props?.f7route?.query?.type) {
        type.value = props?.f7route?.query?.type
      }

      getQrData()
    })

    return {
      link,
      qrDetails
    }
  }
})
</script>

<style>
.survey-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  background-color: #0c4d8f;
  z-index: -1;
}
.survey-bg {
  position: absolute;
  width: 100%;
}
.survey-bg img {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.survey-list-top {
  position: absolute;
  left: 0px;
  width: 100%;
  transform: rotate(180deg);
}

.survey-list-bot {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
